const BasicStates = {
  NONE: -1,
  INITIALIZED: 0,
  GENERATING_TECHNICAL_METADATA: 10, // Old PROPOSED_FOR_DIGITAL_PRESERVATION
  TECHNICAL_METADATA_GENERATED: 20,
  TECHNICAL_METADATA_GENERATION_FAILED: 30,
  INVALID_METADATA: 40,
  METADATA_VALIDATION_FAILED: 50,
  VALIDATED_METADATA_UPDATED: 60,
  VALIDATING_METADATA: 65,
  REJECTED_BY_USER: 70,
  METADATA_CONFIRMED: 75,
  ACCEPTED_TO_DIGITAL_PRESERVATION: 80,
  IN_PACKAGING_SERVICE: 90,
  PACKAGING_FAILED: 100,
  SIP_SENT_TO_INGESTION_IN_DPRES_SERVICE: 110,
  IN_DIGITAL_PRESERVATION: 120,
  REJECTED_IN_DIGITAL_PRESERVATION_SERVICE: 130,
  IN_DISSEMINATION: 140
}
export const States = {
  ...BasicStates,
  ALL_STATES: Object.values(BasicStates)
}
