import Vue from 'vue'
import VueRouter from 'vue-router'
import DatasetsPage from '@/views/DatasetsPage'
import AgreementsPage from '@/views/AgreementsPage'
import ProfilePage from '../views/ProfilePage.vue'
// import FileStorageTokenPage from '@/views/FileStorageTokenPage.vue'
// import CreateFileStorageTokenPage from '@/views/CreateFileStorageTokenPage'
// import FileStorageBrowserPage from '@/views/FileStorageBrowserPage'
import NotAvailablePage from '@/views/NotAvailablePage.vue'
import DatasetPage from '@/views/DatasetPage'
import AgreementPage from '@/views/AgreementPage'
import CreateAgreementPage from '@/views/CreateAgreementPage'
import LoginPage from '@/views/LoginPage'
import AuthenticationService from '@/services/AuthenticationService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/datasets',
    name: 'DatasetsPage',
    component: DatasetsPage
  },
  {
    path: '/datasets/:id',
    name: 'DatasetPage',
    component: DatasetPage
  },
  {
    path: '/agreements',
    name: 'AgreementsPage',
    component: AgreementsPage
  },
  {
    path: '/agreements/:id',
    name: 'AgreementPage',
    component: AgreementPage
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage
  },
  {
    path: '/file-storage',
    name: 'NotAvailablePage',
    component: NotAvailablePage
  },
  {
    path: '/files',
    name: 'NotAvailablePage',
    component: NotAvailablePage
  },
  {
    path: '/file-storage-tokens',
    name: 'NotAvailablePage',
    component: NotAvailablePage
  },
  {
    path: '/create-agreement',
    name: 'CreateAgreementPage',
    component: CreateAgreementPage
  },
  {
    path: '/edit-agreement/:id',
    name: 'EditAgreementPage',
    component: CreateAgreementPage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    beforeEnter: (to, from, next) => {
      const authenticationService = new AuthenticationService()
      if (authenticationService.isAuthenticated()) {
        next({ name: 'DatasetsPage' })
      } else {
        next()
      }
    }
  },
  { path: '/', redirect: '/login' }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const authenticationService = new AuthenticationService()

  if (!authenticationService.isAuthenticated()) {
    try {
      await authenticationService.attemptAuthentication()
    } catch (e) {
      if (e.response.status === 401) {
        // If user was attempting to open a page other than the home page,
        // redirect directly to the SSO login form. This might mean that
        // the user is trying to use a link or the session died suddenly;
        // in both cases, redirecting allows the app to maintain continuity.
        if (to.name !== 'LoginPage') {
          document.location = authenticationService.loginUrl()
        }
      } else {
        throw e
      }
    }
  }

  if (to.name === 'LoginPage' || authenticationService.isAuthenticated()) {
    next()
  } else {
    next({ name: 'LoginPage' })
  }
})

export default router
