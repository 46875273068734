import { render, staticRenderFns } from "./DatasetFileMetadata.vue?vue&type=template&id=aea4b144&scoped=true"
import script from "./DatasetFileMetadata.vue?vue&type=script&lang=js"
export * from "./DatasetFileMetadata.vue?vue&type=script&lang=js"
import style0 from "./DatasetFileMetadata.vue?vue&type=style&index=0&id=aea4b144&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aea4b144",
  null
  
)

export default component.exports